.player-card {
    width: 800px;
    height: 350px;
    background-color: rgba(255, 255, 255, 0.10);
    border-radius: 10px;
    clip-path: polygon(0% 0%, 90% 0, 100% 18%, 100% 100%, 75% 100%, 0 100%);
    margin-top: 2rem;
    transition: all 0.6s ease;
    overflow: hidden;
}

/* .player-card:hover {
    transform: scale(1.03);
    cursor: url("../../assets/graphics/cursor.png"), auto;
} */

/* .player-card:hover .headshot {
    transform: scale(1.05);
    cursor: url("../../assets/graphics/cursor.png"), auto;
}

.player-card:hover .player-team {
    transform: scale(1.05);
    cursor: url("../../assets/graphics/cursor.png"), auto;
} */


.player-card-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.headshot-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
}

.headshot {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    left: 0;
    /* box-shadow: 0px 0px 22px 0px rgba(202, 117, 223, 0.48); */
    transition: all 0.5s ease;
    z-index: 9;
}

.background-shape {
    position: absolute;
    /* top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    background-color: #9173D1;
    /* opacity: 0.5; */
    z-index: -1;
    border-radius: 10px;
    margin-left: 0.5rem;
    margin-bottom: 3rem;
}

.player-team {
    position: relative;
    z-index: 10;
    margin-top: -6.5rem;
    margin-right: -3rem;
    display: flex;
    transition: all 0.6s ease;
}

.player-team-icon {
    border-radius: 100%;
    /* clip-path: polygon(0% 0%, 89% 0, 100% 11%, 100% 100%, 75% 100%, 11% 100%, 0 89%); */
    width: 100px;
    height: 100px;
    margin-left: auto;
    background-color: #9173D1;
    padding: 1.5em;
    z-index: 199;
}

.gamer-text {
    font-family: "Press Start 2P", sans-serif;
    font-size: 2.25rem;
    /* color: #00ff00; */
}

.gamer-graphics {
    position: absolute;
    height: 200px;
    width: 200px;
    z-index: -1;
    /* margin-top: -200px; */
    right: 0;
    bottom: 0;
    margin: 0 auto;
    opacity: 0.05;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.selected-info {
    position: relative;
    width: 50%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 2em;
    z-index: 15;
}

#selected-name {
    margin-top: -5rem;
    text-transform: uppercase;
}

#selected-role {
    font-size: 1rem;
}

#selected-team {
    font-size: 1.25rem;
}

.player-top {
    width: 50%;
    height: 100%;
}

@media screen and (max-width: 800px) {
    .player-card {
        width: 300px;
        height: 425px;
        margin-top: 2rem;
        clip-path: polygon(0% 0%, 85% 0, 100% 12%, 100% 100%, 75% 100%, 0 100%);
    }

    .player-top {
        width: 100%;
        justify-content: center;
        height: 255px;
    }

    .headshot-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 230px;
        margin-top: 1em;
    }

    .headshot {
        position: relative;
        height: 200px;
        width: 200px;
        margin-top: 1em;
        margin-bottom: 1em;
        justify-content: center;
        clip-path: polygon(0% 0%, 89% 0, 100% 11%, 100% 100%, 75% 100%, 11% 100%, 0 89%);
    }

    .player-card-info {
        flex-direction: column;
        justify-content: center;
    }

    .player-team {
        width: 100%;
        margin-top: -2rem;
        margin-left: -1rem;
    }

    .player-team-icon {
        width: 60px;
        height: 60px;
        padding: 0.5em;
    }

    .background-shape {
        display: none;
    }

    .selected-info {
        width: 100%;
        text-align: center;
    }


    #selected-name {
        margin-top: -1.5rem;
        text-transform: uppercase;
        font-size: 1.5rem;
    }

    #selected-role {
        font-size: 0.9rem;
    }

    #selected-team {
        font-size: 1.1rem;
    }


    .gamer-graphics {
        height: 100px;
        width: 100px;
        left: 0;
    }
}