.middlegraphic {
    display: flex;
    background-color: #171041;
    /* background-image: linear-gradient(#470085 60%, #171041); */
    justify-content: center;
    background-image: url("../../assets/backgrounds/Group.png");
    background-size: cover;
    flex-direction: column;
}

.middlegraphic-container {
    width: 100%;
    justify-content: center;
    max-width: 1200px;
    padding-bottom: 20rem;
    padding-top: 20rem;
    margin: 0 auto;
}

.middlegraphic-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.middlegraphic-title {
    color: white;
    font-weight: 500;
    font-size: 30px;
    font-family: "Press Start 2P", sans-serif;
    /* text-shadow: white 1px 1px 20px; */
    /* animation-name: h1glitched;
    animation-duration: calc(1.0s * 1.4);
    animation-iteration-count: infinite;
    animation-timing-function: linear;
} */
}

/* .middlegraphic-title:hover {
    animation-name: h1glitched;
    animation-duration: calc(1.0s * 1.4);
    animation-iteration-count: infinite;
    animation-timing-function: linear;
} */

.middlegraphic-graphics {
    height: 300px;
    width: 100%;
    top: 0;
    margin-top: -250px;
}

@keyframes h1glitched {
    0% {
        transform: skew(-20deg);
        left: -4px;
    }

    10% {
        transform: skew(-10deg);
        left: -4px;
    }

    11% {
        transform: skew(0deg);
        left: 2px;
    }

    50% {
        transform: skew(0deg);
    }

    51% {
        transform: skew(10deg);
    }

    59% {
        transform: skew(10deg);
    }

    60% {
        transform: skew(0deg);
    }

    100% {
        transform: skew(0deg);
    }
}


.middlegraphic-gif {
    margin-top: -100px;
    width: 300px;
}

@media screen and (max-width: 1000px) {
    .middlegraphic {
        height: 550px;
    }

    .middlegraphic-container {
        padding-bottom: 10rem;
        padding-top: 5rem;
    }

    .middlegraphic-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .middlegraphic-title {
        color: white;
        font-weight: 500;
        font-size: 40px;
    }

    .middlegraphic-gif {
        margin-top: 2rem;
        width: 175px;
        margin-bottom: 2rem;
    }
}


@media screen and (max-width: 600px) {
    .middlegraphic-title {
        font-size: 30px;
    }

}

@media screen and (min-width: 601px) and (max-width: 1000px) {
    .middlegraphic {}

}