.about {
    /* height: 750px; */
    display: flex;
    /* background-image: linear-gradient(#04020e 1%, #171041); */
    background-color: #171041;
    justify-content: center;
    color: white;
    padding-top: 5rem;
    /* padding-bottom: 5rem; */
    background-image: url('../../assets/graphics/shapes.svg');
    background-size: contain, auto;
    background-position: center, center;
    background-repeat: no-repeat, no-repeat; 
    overflow: hidden;
    padding-bottom: 12em;
}

/* .about-container {
    position: relative;
    width: 100%;
    justify-content: center;
    max-width: 1200px;
    padding: 2rem;
    background-color: #171041a8;
    border-radius: 50px;
    overflow: hidden; 
} */

.about-container {
    position: relative;
    width: 100%;
    justify-content: center;
    max-width: 1200px;
    z-index: 99;
    /* overflow: hidden; */
}

.border-image-container {
    padding: 2rem;
    clip-path: polygon(0px 25px, 26px 0px, calc(60% - 25px) 0px, 60% 25px, 100% 25px, 100% calc(100% - 10px), calc(100% - 15px) calc(100% - 10px), calc(80% - 10px) calc(100% - 10px), calc(80% - 15px) calc(100% - 0px), 10px calc(100% - 0px), 0% calc(100% - 10px));
    background-color: #110c2f;
}

.about-content {
    display: flex;
    flex-direction: row;
    column-gap: 0rem;
    margin-top: 2rem;
    margin-right: 2rem;
    margin-left: 2rem;
}

.about-graphics {
    position: relative;
    height: 400px;
    width: 400px;
    z-index: 999;
    margin-right: -150px; 
    margin-top: -150px;
    float: right;
}

#aboutComponent {
    display: flex;
    justify-content: space-around;
    /* margin-top: 4rem; */
    flex-direction: column;
    width: 50%;
    height: 100%;
}

#about-graphics-two {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.about-text {
    font-weight: 400;
    text-align: left;
}

#player-card {
    margin: 0 auto;
    margin-top: -50px;
    margin-bottom: 1rem;
}

#about-graphics-middle {
    display: none;
}

@media screen and (max-width: 600px) {
    .about-container {
        margin-bottom: 4rem;
    }

    .about-text {
        font-size: 14px;
    }

    .about-subtitles {
        font-size: 24px;
    }
    #aboutComponent {
        width: 100%;
        padding-right: 50px;
        padding-left: 50px;
    }

    #about-graphics-middle {
        height: 150px;
        width: 150px;
    }

}

@media screen and (max-width: 1200px) {
    .about-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 2rem;
    }

    .about-text {
        font-size: 16px;
    }

    #aboutComponent {
        width: 100%;
        padding-right: 50px;
        padding-left: 50px;
    }

    .about-graphics {
        height: 200px;
        width: 200px;
        right: 100px;
        top: 50px;
    }
    
    #about-graphics-middle {
        display: block;
        margin: 0 auto;
        position: relative;
        height: 200px;
        width: 200px;
        margin-top: 2em;
    }

    #about-graphics-two {
        display: none;
        margin: 0 auto;
    }
}

@media screen and (max-width: 800px) {
    #player-card {
        display: none;
    }
}


@media screen and (max-width: 900px) {
    #aboutComponent {
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
    }

    #about-graphics-two {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .about-container {
        margin-left: 2rem;
        margin-right: 2rem;
        padding-bottom: 2rem;
    }

    /* #player-card {
        display: none;
    } */
}