.colorbutton-container {
    text-align: center;
    margin-top: 20px;
}

.colorbutton {
    padding: 10px 20px;
    background: linear-gradient(180deg, #62CEF7 0%, #BD6EFC 80%), #A3F9FF;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    border-radius: 5px;
    transition: transform 0.3s ease;
    border-radius: 3rem;
    padding: 1rem 3rem;
    align-items: flex-start;
    gap: 0.5rem;
    color: var(--roxo-cybernetico-90, #470085);
    font-weight: 500;
}

.colorbutton:hover {
    transform: scale(1.05);
}
