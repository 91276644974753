.nav {
    width: 100%;
    justify-content: center;
    background-color: white;
    height: 5rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);

}

.methacks-logo {
    display: flex;
    align-items: center;
}

.navbar {
    max-width: 1200px;
    display: flex;
    height: 5rem;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
}

.routes-container {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
}

.routes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 50px;
}

.item {
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    animation-delay: 0.1s;
}

.hamburger-menu {
    display: none;
    cursor: pointer;
    margin: auto;
}

.hamburger-menu .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
}

.hamburger-menu.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.hamburger-menu.active .bar:nth-child(2) {
    opacity: 0;
}

.hamburger-menu.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

@media screen and (max-width: 600px) {
    .routes {
        display: none;
    }

    .hamburger-menu {
        display: block;
        margin-left: 0;
    }

    .methacks-logo {
        display: none;
    }

    .routes-container {
        width: 100%;
        padding-left: 1.5rem;
        padding-right: 0.5rem;
        justify-content: space-between;
    }
    
    #mlhsss {
        width: 80px;
        height: 120px;
    }
}

@media screen and (min-width: 601px) and (max-width: 1000px) {
    .routes {
        display: none;
    }

    .hamburger-menu {
        display: block;
        margin-left: 0;
    }

    .methacks-logo {
        display: none;
    }

    .routes-container {
        width: 100%;
        padding-left: 3rem;
        padding-right: 3rem;
        justify-content: space-between;
    }
}

@media screen and (min-width: 1001px) {
    .menu {
        visibility: hidden;
    }

    .nav {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.menu,
.background {
    position: fixed;
}

.background {
    z-index: 1;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.5s;
}

.hamburger-menu {
    z-index: 3;
    place-items: center;
    background-repeat: no-repeat;
    background-position: center;
}

.menu {
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    transition: 0.5s;
}

.menu-item {
    color: #f9f9f9;
    font-size: 2.5em;
    text-decoration: none;
    animation-delay: 0.1s;
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.menu-item {
    opacity: 0;
}

.menu-item.show {
    animation: appear 0.3s ease-in-out;
    opacity: 1;
}

.nav {
    background-color: #130c35;
    transition: background-color 0.3s ease;
} 

.fade-in-background {
    background-color: #171041f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

}
