.carousel {
    display: flex;
    /* background-color: #331b67; */
    background-image: linear-gradient(#171041 60%, #04020e);
    justify-content: center;
    /* background-image: url("../../assets/backgrounds/Star.png"); */
    background-size: contain;
    margin-top: -10rem;
}

.carousel-container {
    width: 100%;
    justify-content: center;
    max-width: 1200px;
    padding-top: 10rem;
    z-index: 99;
}

.image-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
}

.image-container {
    margin-top: 5rem;
    z-index: 99;
    position: relative;
}

.slider-image {
    /* width: 300px; */
    /* display: inline-block; */
    height: 250px;
    object-fit: cover;
    margin-right: 10px;
    margin-left: 10px;
}

#anim-profiles {
    display: flex;
    flex-direction: row;
    -webkit-overflow-scrolling: touch;
    width: 100%;
}

.carousel-graphics {
    width: 200px;
    height: 200px;
    z-index: 999;
    position: relative;
    margin-top: -75px;
    margin-left: -50px;
}

@media screen and (max-width: 1200px) {
    .slider-image {
        height: 175px;
    }

    .carousel-graphics {
        width: 150px;
        height: 150px;
        margin-left: -10px;

    }
}