.footer {
    display: flex;
    flex-direction: column;
    background-color: #0d0a21;
    /* background-image: linear-gradient(#3a3b4b 75%, #242936); */
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
}

.footer-container {
    width: 100%;
    justify-content: center;
    max-width: 1200px;
    padding: 3.25rem 1.5rem 1.75rem
}

.footer-rowOne {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 25px;
}

.button-arrow {
    /* background-color: #3498db; */
    color: #fff;
    border: none;
    padding: 20px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 40px;
    /* margin: 25px; */
    animation: bounce 2s infinite alternate;
}

.hero-icons {
    padding-top: 1rem;
}

.social-icon {
    color: white;
    font-size: 26px;
    transition: transform 0.3s ease-out;
}

#email, #cc, #conduct {
    color: white;
    font-size: 18px;
}

@media screen and (max-width: 600px) {
    .footer-rowOne {
        flex-direction: column;
        row-gap: 1rem;
        padding-bottom: 1rem;
    }

    #email, #cc, #conduct {
        font-size: 15px;
    }

    .social-icon {
        font-size: 23px;
    }
}