.faq-head {
  /* height: 750px; */
  display: flex;
  /* background-color: #470085; */
  background-color: #171041;
  justify-content: center;
  /* padding-top: 15rem; */
  padding-top: 4rem; 
  background-image: url("../../assets/backgrounds/lines.png");
  background-size: cover;
  background-position: center 30%;
}

.faq-container {
  width: 100%;
  justify-content: center;
  max-width: 1200px;
  margin-bottom: 7rem;
}

.faqs-parent {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-right: 3rem;
  margin-left: 3rem;
  margin-top: -3rem;
  background-color: #171041a8;
  border-radius: 25px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
  z-index: 20;
}

.faqs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 25px;
  width: 50%;
  margin-top: 0;
}

.faq {
  /* width: 40%; */
  color: white;
  margin-top: 1rem;
  padding: 1rem;
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); */
}

.faq-question:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.faq-question {
  padding-right: 2rem;
  width: 100%;
  font-weight: 500;
  font-size: 1.5em;
}

.faq-answer {
  font-size: 20px;
  font-weight: 400;
  font-size: 1.17em;
}

.cursor-pointer {
  width: 100%;
}

.faq .faq-question {
  position: relative;
  /* font-size: 22px; */
  text-align: left;
  /* padding-right: 80px; */
  transition: all 1s ease;
}

.faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 10px;
  height: 30px;
  transition: all 1s ease;
}

.faq .faq-answer {
  /* opacity: 0; */
  /* max-height: 0;
  overflow: hidden; */
  text-align: left;
  transition: all 1s ease;
}

.faq.open .faq-question {
  margin-bottom: 15px;
}

.faq.open .faq-question::after {
  content: "-";
}

/* .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
} */

.faq-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.faq-graphics {
  height: 300px;
  width: 400px;
  margin-top: -70px;
  /* margin-right: 75px; */
  z-index: 0;
}

@media screen and (max-width: 600px) {
  .faq-graphics {
    margin-top: -55px;
    margin: 0 auto;
    width: 200px;
    height: 200px;
  }

  .faqs-parent {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .faqs {
    width: 100%;
  }

  .faq-question {
    font-size: 20px;
  }

  .faq-answer {
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .faq-container {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  .faqs {
    width: 100%;
  }

  .faq {
    width: 100%;
  }

  .faq-question {
    font-size: 1.2em;  
  }

  .faq-answer {
    font-size: 16px;
  }
}