.anglebutton-container {
    background-color: none;
    transition: transform 0.3s ease;
    width: 250px;
}

.anglebutton-container:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.anglebutton-text {
    color: #22184C;
    font-size: 0.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}