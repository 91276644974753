.sponsors {
    display: flex;
    /* background-color: #140E3A;*/
    background-image: linear-gradient(#171041, #140E3A);
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 5rem;
    color: white;
    padding-top: 5rem;
}

.sponsors-container {
    width: 100%;
    justify-content: center;
    max-width: 1200px;
    z-index: 99;
}

.sponsors-grid {
    background: #110c2f;
    /* background-color: rgba(227, 227, 227, 0.404); */
    border-radius: 25px;
    justify-content: center;
    padding: 2rem;
    max-width: 100%;
    box-shadow: 0px 0px 22px 0px rgba(202, 117, 223, 0.48);
}

.sponsor-image-parent {
    padding: 1.5rem;
    width: 200px;
    height: 200px;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 0 auto;
    display: flex;
    transition: transform 0.3s ease-out;
    border-radius: 25px;
}

.medium {
    width: 370px;
}

.large {
    width: 600px;
}

.sponsor-image {
    max-width: 100%;
    max-height: 100%;
    transition: transform 0.3s ease-out;
    /* display: block; */
    margin: 0;
    padding: 0;
}

.sponsor-image-large {
    max-width: 100%;
    max-height: 100%;
    transition: transform 0.3s ease-out;
}

/* 
.sponsor-image-large:hover {
    transform: scale(1.05);
    cursor: pointer;
} */

.sponsor-image-parent:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.sponsor-text-parent {
    align-items: center;
}

.sponsor-text {
    font-size: 18px;
    /* text-decoration: none; */
    color: white;
    font-weight: 400;
}

@media screen and (max-width: 600px) {
    .sponsor-image-parent {
        height: 150px;
        width: 150px;
    }

    .sponsor-image-large {
        height: 100%;
        width: 290px;
    }

    .medium {
        width: 220px;
    }

    .large {
        width: 300px;
    }

    .sponsor-text {
        font-size: 16px;
    }

    .sponsors-container {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .sponsor-text-parent {
        justify-content: center;
    }

    .sponsor-button {
        margin: 0 auto;
        padding-top: 2rem;
    }
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
    .sponsor-image-parent {
        height: 190px;
        width: 190px;
    }

    .sponsor-image-large {
        height: 100%;
        width: 500px;
    }

    .medium {
        width: 270px;
    }

    .large {
        width: 400px;
    }

    .sponsor-text {
        font-size: 16px;    
    }

    .sponsors-container {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .sponsor-text-parent {
        justify-content: center;
    }

    .sponsor-button {
        margin: 0 auto;
        padding-top: 2rem;
    }
}

.sponsors-graphics {
    height: 20rem;
    width: 20rem;
    margin-top: -150px;
    margin-left: -60px;
    transform: rotateY(180deg);
}

@media screen and (max-width: 600px) {
    .sponsors-graphics {
        height: 10rem;
        width: 10rem;
        margin-top: -100px;
        margin-left: -10px;
    }

}

@media screen and (min-width: 601px) and (max-width: 1000px) {
    .sponsors-graphics {
        height: 15rem;
        width: 15rem;
        margin-top: -130px;
        margin-left: -30px;
    }

}