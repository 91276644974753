.widget {
    position: absolute;
    width: 100px;
    height: 100px;
    z-index: 1;
    transition: transform 0.3s ease;
}

.widget:hover {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 1200px) {
    .widget {
        display: none;
    }
}