.team {
    /* height: 500px; */
    display: flex;
    /* background-image: linear-gradient(#470085 60%, #171041); */
    background-color: #171041;
    justify-content: center;
    padding-bottom: 5rem;
    color: white;
    padding-top: 5rem;
}

.team-container {
    width: 100%;
    justify-content: center;
    max-width: 1200px;
}

.team-content {
    display: flex;
    column-gap: 32px;
    align-items: center;
    flex-direction: column;
}

/* .team-grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    align-items: center;
    justify-content: center;
    column-gap: 7px;
    row-gap: 7px;
    padding-top: 7px;
    padding-bottom: 7px;
} */

.team-grid {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.team-grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    overflow: hidden;
}

.grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;
    margin: 0 auto;
}

.team-buttons {
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 2rem;
    font-size: 3em;
    font-weight: 500;
}


.team-grid-subTitle {
    text-align: center;
    width: 100%;
    padding: 5px;
    margin-bottom: 35px;
    margin-top: 4rem;
    text-transform: uppercase;
    color: #92f7a8;
    letter-spacing: 0.1em;
    text-align: center;
    font-size: 1.25em;
    text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.6);
    font-family: "Press Start 2P", sans-serif;
}

.member {
    width: 120px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: skew(-15deg);
    transition: all 0.3s ease;
}

.member:hover {
    transform: skew(-15deg) scale(1.12);
}

.member-photo {
    height: 125px;
    width: 120px;
    background: rgba(255, 255, 255, 0.5);
    filter: grayscale(85%);
    transition: filter 0.3s ease;
}

.member-photo.selected {
    filter: grayscale(0%);
    animation: flash 400ms linear;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
    outline: 4px solid white;
}

.member-name {
    display: none;
    position: absolute;
    width: 120px;
    margin-top: 110px;
    background: #3c076bdb;
    color: rgb(214, 212, 212);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1em;
}

.member-name.selected {
    display: block;
}

.team-grid:hover {
    cursor: url("../../assets/graphics/cursor.png"), auto;
}

.selected-label {
    position: absolute;
    margin-top: -120px;
    padding: 4px 10px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 2rem;
    z-index: 99;
    color: #3c076bdb;
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 1);
}


/* #selected-name,
#selected-role {
    color: #FFF;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
} */

/* #selected-extra {
    color: #FFF;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
} */

@keyframes flash {
    0% {
        filter: brightness(100%);
    }

    20% {
        filter: brightness(150%);
    }

    40% {
        filter: brightness(100%);
    }

    60% {
        filter: brightness(150%);
    }

    80% {
        filter: brightness(100%);
    }

    100% {
        filter: brightness(150%);
    }
}



@media screen and (max-width: 600px) {
    .headshot {
        /* width: 200px;
        height: 200px; */
    }

    /* .headshot-container {
        width: 200px;
        height: 200px;
    } */

    .team-content {
        flex-direction: column;
        justify-content: center;
    }

    .member {
        width: 100px;
        height: 100px;
    }

    .member-photo {
        height: 100px;
        width: 100px;
    }

    .team-grid-container {
        /* width: 100%; */
        margin-top: 50px;
    }

    .team-grid-subTitle {
        width: 100%;
        font-size: 1em;
        margin-top: 0;
    }

    .team-buttons {
        padding-top: 1rem;
    }

    /* .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    } */
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
    .headshot {
        /* width: 200px;
        height: 200px; */
    }

    /* .headshot-container {
        width: 200px;
        height: 300px;
    } */

    .team-content {
        flex-direction: column;
        justify-content: center;
    }

    /* .member {
        width: 100px;
        height: 100px;
    }

    .member-photo {
        height: 75px;
        width: 75px;
    } */

    .team-grid-container {
        /* width: 100%; */
        margin-top: 50px;
    }

    .team-grid-subTitle {
        width: 100%;
        font-size: 1.25em;
        margin-top: 0;
    }

    /* 
    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    } */
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
    .team-content {
        flex-direction: column;
        justify-content: center;
    }

    .headshot {
        /* width: 300px; */
        /* height: 300px; */
    }

    /* .headshot-container {
        width: 400px;
        height: 500px;
    } */

    .team-grid-container {
        /* width: 100%; */
        margin-top: 75px;
    }

    /* .grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;
    } */
}

@media screen and (max-width: 1200px) {
    .team-container {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .grid {
        display: flex;
    }

}