.stats {
    display: flex;
    /* background-color: #171041; */
    justify-content: center;
    padding: 0rem;
    color: white;
    /* background-image: url("../../assets/backgrounds/Group\ 9.png"); */
    background-image: url('../../assets/graphics/Wave.svg'), linear-gradient(#04020e 70%, #171041);
    background-size: contain, auto;
    background-position: bottom, 0;
    background-repeat: no-repeat, no-repeat; 
    padding-top: 6rem;
    padding-bottom: 8rem;
    /* padding-bottom: 18rem; */
}

.stats-container {
    width: 100%;
    justify-content: center;
    max-width: 1200px;
    padding: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-evenly;
    background-color: #110c2f;
    box-shadow: 10px 10px 22px 0px rgba(202, 117, 223, 0.48);
    clip-path: polygon(100% 0, 100% 82%, 94% 100%, 0% 100%, 0 18%, 5% 0);
    z-index: 999;
}

.facts {
    display: flex;
    align-items: center;
}

.facts-description {
    margin-left: 1rem;
}

.facts-text {
    font-weight: 600;
    font-size: 18px;
    font-family: "Press Start 2P", sans-serif;
}

.facts-num {
    font-weight: 900;
    font-size: 1.75em;
    color: #aef480;
    font-family: "Press Start 2P", sans-serif;
}

#statsComponent {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
    padding-left: 2rem;
    padding-right: 2rem;
}

@media screen and (max-width: 600px) {
    .stats {
        padding-top: 2rem;
        /* padding-right: 2rem; */
    }

    .stats-container {
        flex-direction: column;
        justify-content: center;
        row-gap: 25px;
    }

    .facts {
        justify-content: center;
    }

    .facts-text {
        font-weight: 600;
        font-size: 18px;
    }

    .facts-num {
        font-family: 'Lato', sans-serif;
        font-weight: 900;
        font-size: 2.5em;
        color: #CA8B77;
    }

}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .stats {
        padding: 1rem;
    }

    .facts-text {
        font-weight: 600;
        font-size: 18px;
    }

    .facts-num {
        font-family: 'Lato', sans-serif;
        font-weight: 900;
        font-size: 1.5em;
        color: #CA8B77;
    }
}