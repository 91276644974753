.footergraphic {
    display: flex;
    background-image: url('../../assets/graphics/grid black.svg'), linear-gradient(#150f3dac 40%, #0f0b28);
    /* background-color: #0f0b28; */
    justify-content: center;
    margin-top: -600px;
    min-height: 600px;
    max-height: 800px;
}

.footergraphic-container {
    width: 100%;
    justify-content: center;
    /* background-image: url('../../assets/graphics/grid.svg'); */
    max-height: 800px;
}

.footergraphic-container {
    position: relative;
}

@media screen and (max-width: 600px) {
    .footergraphic {
        margin-top: -200px;
        min-height: 200px;
        background-position: center;
    }
}

@media screen and (min-width: 601px) and (max-width: 1000px) {
    .footergraphic {
        margin-top: -400px;
        min-height: 400px;
        background-position: center;
    }

}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
    .footergraphic {
        margin-top: -500px;
    }

}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
    .footergraphic {
        margin-top: -700px;
    }

}